import { Alert, Button, Card, Col, Container, FloatingLabel, Form, FormLabel, InputGroup, Row, Spinner, Stack, Table } from "react-bootstrap";
import { useGetLMDQuery, useGetVendorsQuery, useLazyGetAppReportQuery, useLazyGetAppReportsQuery } from "../store/api";
import moment from 'moment'
import prettyBytes from 'pretty-bytes'
import { useEffect, useState } from "react";

export default function ReportsBrowser() {
    const getVendorsResponse = useGetVendorsQuery()
    const getLMDResponse = useGetLMDQuery()
    const [ getReports, getReportsResponse ] = useLazyGetAppReportsQuery()

    const [ lmd, setLMD ] = useState(null)
    const [ from, setFrom ] = useState('')
    const [ to, setTo ] = useState('')
    const [ extensions, setExtensions ] = useState('')
    const [ vendorIds, setVendorIds ] = useState('')
    const [ fileName, setFileName ] = useState('')

    const handleGetReports = e => {
        e.preventDefault()

        triggerGetReports()
    }

    const triggerGetReports = () => {
        getReports(generateQueryString({
            from,
            to,
            fileName,
            extensions,
            vendorIds
        }))
    }

    console.log({ getReportsResponse })

    const formLoading = getReportsResponse.isLoading || getReportsResponse.isFetching

    useEffect(() => {
        if (!lmd && getLMDResponse.data)
            setLMD(getLMDResponse.data.lmd)
    }, [ getLMDResponse ])

    useEffect(() => {
        if (lmd) {
            setFrom(lmd)
            setTo(lmd)
        }
    }, [ lmd ])

    useEffect(() => {
        if (getReportsResponse.isUninitialized && from && to)
            triggerGetReports()
    }, [ getReportsResponse, from, to ])

    if (getVendorsResponse.isLoading || getLMDResponse.isLoading)
        return <Spinner />

    return (
        <Container>
            <Stack gap={ 4 }>
                <Card className="mt-2">
                    <Card.Header>
                        <Card.Title>
                            Reports Browser
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={ handleGetReports }>
                            <Stack gap={ 2 }>
                                <Row>
                                    <Col xs={ 12 } className="mt-1 mb-1">
                                        <InputGroup size="sm">
                                            <InputGroup.Text>
                                                File name
                                            </InputGroup.Text>
                                            <Form.Control type="text" value={ fileName } onChange={ e => setFileName(e.target.value) } />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={ 6 } className="mt-1 mb-1">
                                        <Stack gap={ 2 }>
                                            <InputGroup size="sm">
                                                <InputGroup.Text>
                                                    Vendor
                                                </InputGroup.Text>
                                                <Form.Select value={ vendorIds } onChange={ e => setVendorIds(e.target.value) }>
                                                    <option value="">Any</option>
                                                    { getVendorsResponse.data?.map(o => <option key={ o.vendor_id } value={ o.vendor_id }>{ o.vendor_name }</option>) }
                                                </Form.Select>
                                            </InputGroup>
                                            <InputGroup size="sm">
                                                <InputGroup.Text>
                                                    File type
                                                </InputGroup.Text>
                                                <Form.Select value={ extensions } onChange={ e => setExtensions(e.target.value) }>
                                                    <option value="">Any</option>
                                                    <option value="csv">CSV</option>
                                                    <option value="html">HTML</option>
                                                    <option value="pdf">PDF</option>
                                                    <option value="XLS">XLS</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Stack>
                                    </Col>
                                    <Col xs={ 6 } className="mt-1 mb-1">
                                        <Stack gap={ 2 }>
                                            <InputGroup size="sm">
                                                <InputGroup.Text>
                                                    From
                                                </InputGroup.Text>
                                                <Form.Control type="date" value={ from } onChange={ e => setFrom(e.target.value) } />
                                            </InputGroup>
                                            <InputGroup size="sm">
                                                <InputGroup.Text>
                                                    To
                                                </InputGroup.Text>
                                                <Form.Control type="date" value={ to } onChange={ e => setTo(e.target.value) } />
                                            </InputGroup>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" size="sm" type="submit" disabled={ formLoading }>
                                            { formLoading ? 'Searching...' : 'Search' }
                                        </Button>
                                    </div>
                                </Row>
                            </Stack>
                        </Form>
                    </Card.Body>
                </Card>
                {
                    !getReportsResponse.isUninitialized && <DynamicTable
                        data={ getReportsResponse.data?.reportFiles }
                        isLoading={ getReportsResponse.isLoading }
                    />
                }
            </Stack>
        </Container>
    )
}

export function DynamicTable({ data = [], isLoading }) {
    const [ getAppReport, getAppReportResponse ] = useLazyGetAppReportQuery()
    const [ page, setPage ] = useState(1)
    const numberOfPages = Math.ceil(data.length / 10)

    useEffect(() => {
        if (getAppReportResponse.currentData)
            window.open(getAppReportResponse.currentData.presignedUrl)
    }, [ getAppReportResponse ])

    const startIndex = (page - 1) * 10
    const endIndex = startIndex + 10

    const dataToDisplay = data
        .slice(startIndex, endIndex)

    const handlePrevPage = () => setPage(page - 1)
    const handleNextPage = () => setPage(page + 1)

    if (isLoading)
        return <Spinner />

    return (
        <Card className="mb-2">
            <Card.Body>
                {
                    Boolean(numberOfPages) &&
                    <Stack direction="horizontal">
                        <FormLabel>
                            Page { page } of { numberOfPages }
                        </FormLabel>
                        <div className="ms-auto"></div>
                        <Stack direction="horizontal" gap={ 2 }>
                            <Button onClick={ handlePrevPage } disabled={ page === 1 }>Prev</Button>
                            <Button onClick={ handleNextPage } disabled={ page === numberOfPages}>Next</Button>
                        </Stack>
                    </Stack>
                }
            </Card.Body>
            {
                dataToDisplay.length ?
                <Table className="mb-2" variant="striped" responsive>
                    <thead>
                        <tr>
                            <th>Vendor</th>
                            <th>Date</th>
                            <th>File Name</th>
                            <th>Size</th>
                            <th>Last Modified</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        { dataToDisplay.map(reportInfo => <ReportBrowserTableRow key={ reportInfo.Key } reportInfo={ reportInfo } getAppReport={ getAppReport } />) }
                    </tbody>
                </Table>
                : <Card.Body><Alert variant="warning">No results to display</Alert></Card.Body>
            }
        </Card>
    )
}

function ReportBrowserTableRow({ reportInfo, getAppReport }) {
    const getVendorsResponse = useGetVendorsQuery()
    const [ vendorId, date ] = reportInfo.Key.split('/')

    return (
        <tr>
            <td>
                { getVendorsResponse.data?.find(v => v.vendor_id == vendorId).vendor_name }
            </td>
            <td>
                { moment(date).format('MM/DD/YYYY') }
            </td>
            <td>
                { reportInfo.Key.split('/').slice(2) }
            </td>
            <td>
                { prettyBytes(reportInfo.Size, { maximumFractionDigits: 0 }) }
            </td>
            <td>
                { moment(reportInfo.LastModified).format('MM/DD/YYYY hh:mm:ss A') }
            </td>
            <td style={ { textAlign: 'end' } }>
                <Button size="sm" onClick={ () => getAppReport(reportInfo.Key) }>
                    Download
                </Button>
            </td>
        </tr>
    )
}

function generateQueryString(query) {
    let queryObj = {}

    if (query.from && query.to && query.from === query.to)
        queryObj.exact = `exact=${query.from}`

    if (query.from && !queryObj.exact)
        queryObj.from = `from=${query.from}`

    if (query.to && !queryObj.exact)
        queryObj.to = `to=${query.to}`

    if (query.extensions)
        queryObj.extensions = `extensions=${query.extensions}`

    if (query.vendorIds)
        queryObj.vendorIds = `vendorIds=${query.vendorIds}`

    if (query.fileName)
        queryObj.fileName = `fileName=${query.fileName}`

    console.log({ query, queryObj })

    return Object.values(queryObj).join('&')
}