import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = 'https://test-api.v2.stadionsales.com'

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl,
        mode: 'cors',
        credentials: 'include'
    }),
    endpoints: builder => ({
        getAppReports: builder.query({
            query: query => ({
                url: `/reports/apps?${query}`
            }),
            providesTags: [ 'App-Reports' ]
        }),
        getAppReport: builder.query({
            query: key => ({
                url: `/reports/apps/${encodeURIComponent(key)}`
            })
        }),
        getVendors: builder.query({
            query: () => ({
                url: '/util/vendors',
                providesTags: [ 'Vendors' ]
            })
        }),
        getLMD: builder.query({
            query: () => ({
                url: '/util/lmd',
                providesTags: [ 'LMD' ]
            })
        })
    })
})

export const {
    useGetAppReportsQuery,
    useLazyGetAppReportsQuery,
    useLazyGetAppReportQuery,
    useGetVendorsQuery,
    useGetLMDQuery
} = api