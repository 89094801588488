import { Container, ListGroup, Navbar, Offcanvas } from 'react-bootstrap'
import { Link, Outlet, Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from 'react-router-dom'
import logo from '../media/stadionLogoIcon3.png'
import { useState } from 'react'
import routes from '../routes'
import ReportsBrowser from '../pages/ReportsBrowser'

export default function Layout() {
    const [ showMenu, setShowMenu ] = useState(false)

    const handleShow = () => setShowMenu(true)
    const handleHide = () => setShowMenu(false)

    return (
        <>
            <Router>
                <Navbar expand="lg" fixed="sticky" className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand onClick={ handleShow }>
                            <img
                                alt=""
                                src={ logo }
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                            />{' '}
                            StadionSales
                        </Navbar.Brand>
                    </Container>
                </Navbar>
                <Offcanvas show={ showMenu } onHide={ handleHide }>
                    <NavLinks />
                </Offcanvas>
                <Routes>
                    <Route path='/reports' element={ <ReportsBrowser />} >
                    </Route>
                </Routes>
                <Outlet />
            </Router>
        </>
    );
}

function NavLinks() {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <ListGroup variant="flush">
            {
                routes.map(route => <ListGroup.Item
                    key={ route.pathname }
                    action
                    onClick={ () => navigate(route.pathname) }
                    active={ location.pathname === route.pathname }
                >
                    { route.title }
                </ListGroup.Item>
                )
            }
        </ListGroup>
    )
}